import { render, staticRenderFns } from "./FooterLogos.vue?vue&type=template&id=62dc4748&scoped=true"
import script from "./FooterLogos.vue?vue&type=script&lang=js"
export * from "./FooterLogos.vue?vue&type=script&lang=js"
import style0 from "./FooterLogos.vue?vue&type=style&index=0&id=62dc4748&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62dc4748",
  null
  
)

export default component.exports