<template>
  <div v-if="logos" class="footer-logos">
    <div v-for="(logo, index) in logos" :key="index" class="logo">
      <img
        :src="logo.image.filename"
        :alt="logo.image.alt ? logo.image.alt : ''"
        height="24"
        width="60"
        loading="lazy"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLogos',
  props: {
    logos: {
      type: Array,
      required: false,
      default() {},
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-logos {
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: spacing('small');
  padding-left: spacing('medium');
  padding-right: spacing('medium');
  padding-bottom: spacing('medium');

  .logo {
    height: 2.4rem;
    width: auto;
    display: inline-block;

    img {
      height: 100%;
      max-width: 4rem;
      filter: brightness(0%) invert(1);
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .footer-logos {
    gap: spacing('xlarge');
    padding-top: 12rem;
    padding-bottom: 0;

    .logo {
      img {
        max-width: 6rem;
      }
    }
  }
}
</style>
